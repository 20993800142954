var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.265.2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import getConfig from 'next/config'
import { init, Replay, BrowserTracing } from '@sentry/nextjs'

import packageInfo from './package.json'

const { publicRuntimeConfig } = getConfig()
const ENVIRONMENT = publicRuntimeConfig?.NEXT_PUBLIC_ENVIRONMENT
const SENTRY_DSN = process.env.SENTRY_DSN || publicRuntimeConfig.NEXT_PUBLIC_SENTRY_DSN

init({
  environment: ENVIRONMENT,
  dsn: SENTRY_DSN,
  release: packageInfo?.version,
  integrations: [
    new BrowserTracing(),
    new Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: ENVIRONMENT === 'production' ? 1.0 : 0.0,
  enabled: ENVIRONMENT === 'production',
  autoSessionTracking: true, // Release Health
})
